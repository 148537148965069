import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import readNumber from "read-vn-number";
import clientService from "../../../services/http-client";
import Footer from "../../footer";
import Header from "../../header";
import ChungNhanView from "./chungNhanView";

const ChungNhan = () => {
  const { id } = useParams();
  const [html, setHtml] = useState("");
  const { data, isLoading } = useQuery({
    queryKey: ["khoaHocGetChungNhanPublic", { id }],
    queryFn: () => clientService.api.khoaHocGetChungNhanPublic(id),
    enabled: !!id,
  });
  useEffect(() => {
    console.log(data);
    if (data?.khoaHoc?.loaiChungChi?.fileMau?.path) {
      const replaceText = (text, data) => {
        Object.keys(data).forEach((key) => {
          text = text.replace(new RegExp(`{{${key}}}`, "gi"), data[key]);
        });
        return text;
      };
      const fetchData = async () => {
        const response = await fetch(data?.khoaHoc?.loaiChungChi?.fileMau?.path);
        let text = await response.text();
        const resChuKy = await fetch(data?.khoaHoc?.loaiChungChi?.chuKyBGD?.path);
        const chuKy = await resChuKy.blob();
        const maQR = `${data.soChungNhan}/VNCYS/${data?.khoaHoc?.loaiChungChi?.soHieu}`;
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const capitalizeFirstLetter = (str) => str && str.charAt(0).toUpperCase() + str.slice(1);
        text = replaceText(text, {
          soChungNhan: data.soChungNhan,
          tenCoSoCapChungNhan: `VNCYS/${data?.khoaHoc?.loaiChungChi?.soHieu}`,
          tenHocVien: `${data.hocVien.hocHam?.ten ?? ""} ${data.hocVien.hocVi?.ten ?? ""} ${data.hocVien.user.fullName}`,
          ngaySinh: data.hocVien.user.birthdate ? dayjs(data.hocVien.user.birthdate).format("DD/MM/YYYY") : "",
          donViCongTac: data.hocVien.donViCongTac ? data.hocVien.donViCongTac : "",
          tenKhoaHoc: data.khoaHoc.tenKhoaHoc,
          ngayKhaiGiang: dayjs(data.khoaHoc.ngayKhaiGiang).locale("vi").format("[ngày] D [tháng] M [năm] YYYY"),
          ngayKetThuc: dayjs(data.khoaHoc.ngayKetThuc).locale("vi").format("[ngày] D [tháng] M [năm] YYYY"),
          soTiet: data.khoaHoc.soTiet,
          soGio: String(data.khoaHoc.soTiet).padStart(2, "0"),
          soGioChu: capitalizeFirstLetter(readNumber(data.khoaHoc.soTiet)),
          ngayCapCn: data.ngayCapCN ? dayjs(data.ngayCapCN).locale("vi").format("[Ngày] D [tháng] M [năm] YYYY") : "",
          anhChuKy: `<img src="${URL.createObjectURL(chuKy)}" width="350" height="250" />`,
          maQR: maQR,
          anhQR: `<img src="data:image/svg+xml;base64,${btoa(svgData)}" width="100" height="100" />`,
        });
        setHtml(text);
      };
      fetchData();
    }
  }, [data]);
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu="support" />

        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Giấy chứng nhận</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Giấy chứng nhận
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content" style={{ backgroundColor: "white" }}>
          <div className="container">
            <div hidden>
              <QRCode id="QRCode" value={`https://bimeri.edu.vn/chung-nhan/${data?.soChungNhan}`} />
            </div>

            <div>
              <ChungNhanView data={html ?? ""} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default ChungNhan;
