import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import clientService from "../../../../services/http-client";
import Footer from "../../../footer";
import Header from "../../../header";
import { Icon1, Timer, User1 } from "../../../imagepath";
import DetailsContent from "./detailsContent";
const CourseDetails = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["khoaHocDetail", { id }],
    queryFn: () => clientService.api.khoaHocDetail(id),
    enabled: !!id,
  });

  const tutor = data?.coCauNhanSu?.find((item) => item.chucVu?.id === 2);
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"course"} />
        <div
          className="inner-banner"
          style={{ backgroundImage: "url(" + `"${data?.hinhAnh?.path}"` + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                    <div className="abt-instructor-img">
                      <img
                        src={tutor?.user?.avatar?.path ?? User1}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="instructor-detail me-3">
                      <h5>{tutor?.user?.fullName}</h5>
                    </div>
                  </div>
                </div>
                <h2>{data?.tenKhoaHoc}</h2>
                <p>{data?.moTaNgan}</p>
                <div className="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                  <div className="cou-info">
                    <img src={Icon1} alt="" />
                    <p>Số giờ học: {data?.soTiet}</p>
                  </div>
                  <div className="cou-info">
                    <img src={Timer} alt="" />
                    {data?.ngayKhaiGiang ? (
                      <p>
                        Lịch học:{" "}
                        {dayjs(data?.ngayKhaiGiang).format("DD/MM/YYYY")} -{" "}
                        {data?.ngayKetThuc
                          ? dayjs(data?.ngayKetThuc).format("DD/MM/YYYY")
                          : "Không xác định"}
                      </p>
                    ) : (
                      <p>Lịch học: Chưa cập nhật</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DetailsContent />

        <Footer />
      </div>
    </>
  );
};

export default CourseDetails;
