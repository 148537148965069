import { useQuery } from "@tanstack/react-query";
import clientService from "../../services/http-client";
import Footer from "../footer";
import Header from "../header";
const About = () => {
  // activeMenu={"Details"}
  const { data, isLoading } = useQuery({
    queryKey: ["aboutFindById"],
    queryFn: () => clientService.api.aboutFindById(1),
  });
  return (
    <>
      <div className="main-wrapper">
        <Header activeMenu={"about"} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Về David Education</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Về David Education
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <section className="course-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="section-header">
                  <div className="section-sub-head">
                    <span>Thông tin về chúng tôi</span>
                    <h2>{data?.tieuDe}</h2>
                  </div>
                </div>
                <div className="section-text">
                  <div dangerouslySetInnerHTML={{ __html: data?.noiDung }} />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <img src={data?.hinhAnh?.path} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default About;
