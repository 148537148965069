import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cacheService from "../../services/cache-service";
import { logo } from "../imagepath";

const Header = (props) => {
  const [info, setInfo] = useState();

  useEffect(() => {
    document.body?.classList?.remove("menu-opened");
    setInfo(cacheService.getInfo());
    return () => {
      document.body.className = "";
    };
  }, []);
  // change header background on scroll
  const [navbar, setNavbar] = useState(false);
  // Mobile Menu toggle
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  useEffect(() => {
    if (openMobileMenu) {
      document.body?.classList?.add("menu-opened");
    } else {
      document.body?.classList?.remove("menu-opened");
    }
  }, [openMobileMenu]);

  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeHeaderBackground);
  return (
    <header className="header">
      <div className="header-fixed">
        <nav
          className={navbar ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg" : "navbar navbar-expand-lg header-nav scroll-sticky"}
        >
          <div className="container">
            <div className="navbar-header">
              <Link id="mobile_btn" to="/" onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={logo} className="img-fluid" alt="Logo" width={60} />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img src={logo} className="img-fluid" alt="Logo" width={60} />
                </Link>
                <Link id="menu_close" className="menu-close" to="/" onClick={() => setOpenMobileMenu(false)}>
                  <i className="fas fa-times" />
                </Link>
              </div>
              <ul className="main-nav">
                <li className={!props.activeMenu ? "active" : ""}>
                  <Link className={mobileSubMenu ? "submenu" : ""} to="/">
                    Trang chủ
                  </Link>
                </li>
                <li className={`has-submenu ${props.activeMenu === "about" ? "active" : ""}`}>
                  <Link to="/about">Về David Education</Link>
                </li>
                <li className={`has-submenu ${props.activeMenu === "course" ? "active" : ""}`}>
                  <Link to="/course">Khóa học</Link>
                </li>
                <li className={`has-submenu ${props.activeMenu === "news" ? "active" : ""}`}>
                  <Link to="/news">Thông tin đào tạo</Link>
                </li>
                <li className={`has-submenu ${props.activeMenu === "faq" ? "active" : ""}`}>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className={`has-submenu ${props.activeMenu === "support" ? "active" : ""}`}>
                  <Link to="/support">Liên hệ</Link>
                </li>
                {openMobileMenu && !info?.id && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link header-sign" to="/login">
                        Đăng nhập
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link header-login" to="/register">
                        Đăng ký
                      </Link>
                    </li>
                  </>
                )}
                {openMobileMenu && info?.id && (
                  <>
                    <li className="nav-item">
                      <a className="nav-link header-sign" href={`${process.env.REACT_APP_FRONTEND_URL}/dashboard/`} target="_blank">
                        Trang của tôi
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link header-login" to="/logout">
                        Đăng xuất
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {!info?.id && (
              <ul className="nav header-navbar-rht">
                <li className="nav-item">
                  <Link className="nav-link header-sign" to="/login">
                    Đăng nhập
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to="/register">
                    Đăng ký
                  </Link>
                </li>
              </ul>
            )}
            {info?.id && (
              <ul className="nav header-navbar-rht">
                <li className="nav-item">
                  <a className="nav-link header-sign" href={`${process.env.REACT_APP_FRONTEND_URL}/dashboard/`} target="_blank">
                    Trang của tôi
                  </a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to="/logout">
                    Đăng xuất
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
